import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "College Tours"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "college-tours"
    }}>{`College Tours`}</h1>
    <h3 {...{
      "id": "following-covid19-advice-from-the-victorian-government-the-victorian-chief-health-officer-and-the-department-of-health-and-human-services-dhhs-de-la-salle-college-will-not-currently-be-holding-any-on-site-college-tours"
    }}>{`Following COVID‐19 advice from the Victorian Government, the Victorian Chief Health Officer and the Department of Health and Human Services (DHHS), De La Salle College will not currently be holding any on site College Tours.`}</h3>
    <p>{`To ensure everyone has an opportunity to experience De La Salle College, a Virtual Tour of all 3 campuses is available `}<a parentName="p" {...{
        "href": "https://www.delasalle.vic.edu.au/virtualtour/"
      }}>{`here`}</a>{`. There are 4 Information videos giving you a deeper insight into our College and our Community. In addition, we are offering online Q&A Information sessions whereby you can dial in and we will answer your questions in real time and supply further information.`}</p>
    <p>{`Information Sessions commenced in June and have proved extremely helpful and every session has been well attended and successful.`}</p>
    <h4 {...{
      "id": "how-to-book-a-qa-session"
    }}>{`HOW TO BOOK A Q&A SESSION`}</h4>
    <ul>
      <li parentName="ul">{`Choose a suitable session date from dates below and click to book with your details.`}</li>
      <li parentName="ul">{`You will receive log in details via email, prior to your chosen session.`}</li>
      <li parentName="ul">{`Use the login link to participate.`}</li>
    </ul>
    <p>{`If you have any questions, please do not hesitate to contact the Admission Team via email `}<a parentName="p" {...{
        "href": "mailto:enrolment@delasalle.vic.edu.au"
      }}>{`enrolment@delasalle.vic.edu.au`}</a></p>
    <h4 {...{
      "id": "qa-information-sessions"
    }}>{`Q&A INFORMATION SESSIONS`}</h4>
    <p><strong parentName="p">{`AUGUST 2020`}</strong></p>
    <p>{`Thursday 6 August 11:30am `}<a parentName="p" {...{
        "href": "https://www.trybooking.com/book/event?eid=641158&"
      }}>{`CLICK HERE`}</a>{` to Book`}</p>
    <p>{`Thursday 13 August 11:30am `}<a parentName="p" {...{
        "href": "https://www.trybooking.com/book/event?eid=641169&"
      }}>{`CLICK HERE`}</a>{` to Book`}</p>
    <p><strong parentName="p">{`YEAR 7 INFORMATION EVENT 2020`}</strong></p>
    <p>{`Wednesday 5 August 7:30pm `}<a parentName="p" {...{
        "href": "https://www.trybooking.com/BKPFR"
      }}>{`CLICK HERE`}</a>{` to Book (`}<a parentName="p" {...{
        "href": "https://www.delasalle.vic.edu.au/wp-content/uploads/2020/07/Year7_2022_information_session.pdf"
      }}>{`PDF`}</a>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      